import forEach from 'lodash/forEach';

export const createMessageBus = () => {
  const listeners = {};
  return {
    on: (name, listener) => {
      if (!listeners[name]) {
        listeners[name] = [];
      }
      listeners[name].push(listener);
    },
    emit: (name, ...args) => {
      forEach(listeners[name], (listener) => {
        listener(...args);
      });
    },
  };
};

const messageBus = createMessageBus();

export default messageBus;
