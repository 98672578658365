import {
  registerStore,
} from '../common/utilsClient/multiReducer';

const initialState = {
  userLanguage: null,
  fontSize: 16,
  animationSpeed: 400,
  theme: null,
  // FIXME: Move to another location
  dexcomAccessToken: null,
  dexcomAccessTokenExpiresAt: null,
  dexcomRefreshToken: null,
  originalFormURL: null,
};

const store = registerStore({
  path: 'preferences',
  initialState,
});

export const getAnimationSpeed = store.get('animationSpeed');

export const setUserLanguage = store.create.set('userLanguage');
export const getUserLanguage = store.get('userLanguage');

export default store;
