import React, {
  Suspense,
} from 'react';
import PropTypes from 'prop-types';
import {
  ApolloClient,
  ApolloProvider,
} from '@apollo/client';
import {
  Provider,
} from 'react-redux';
import Loading from '../common/components/Loading';
import Router from '../routes/Router';
import ThemeProvider from './ThemeProvider';
import DataProvider from './DataProvider';
import useLoading from './useLoading';
import tracker from '../utils/tracker';

const App = ({
  store,
  history,
  client,
  promise,
}) => {
  const isLoading = useLoading(promise);

  if (isLoading) {
    return null;
  }

  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <DataProvider>
            <Suspense fallback={<Loading />}>
              <Router history={tracker.connectToHistory(history)} />
            </Suspense>
          </DataProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  promise: PropTypes.instanceOf(Promise),
  client: PropTypes.instanceOf(ApolloClient).isRequired,
};

App.defaultProps = {
  promise: null,
};

export default App;
