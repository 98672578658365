import map from 'lodash/map';
import find from 'lodash/find';
import some from 'lodash/some';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import isBoolean from 'lodash/isBoolean';
import forEach from 'lodash/forEach';
import sortBy from 'lodash/sortBy';
import last from 'lodash/last';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import {
  useSelector,
  useDispatch,
} from 'react-redux';
import {
  useQuery,
} from '@apollo/client';
import gql from 'graphql-tag';
import useReconcile from '../common/utilsClient/useReconcile';
import {
  selectToken,
} from '../store/token';
import {
  isNotCompleted,
  getStaged,
  STATE_DRAFT,
  deleteDraft,
} from '../store/stage';
import useOnline from '../utils/useOnline';
import useQuestionnaire, {
  useLatestDraftData,
} from '../utils/useQuestionnaire';

const DataContext = createContext();

export const GET_ACTIVITIES = gql`
  query GetActivities {
    state
    my {
      id
      languagePreference
      activities {
        id
        project {
          id
          name
          logoUrl
          languages
          templates {
            id
            subject
            content
            purpose
            language
          }
        }
        milestoneName
        clinicianName
        clinicianPhotoUrl
      }
      answersSheets {
        id
        state
        patientId
        activityId
        orderInActivity
        questionnaire {
          id
          name
          minutesToComplete
          translations {
            language
            questionnaireName
          }
        }
      }
    }
  }
`;

const DataProvider = ({
  // eslint-disable-next-line react/prop-types
  children,
}) => {
  const online = useOnline();
  const {
    loading,
    data,
  } = useQuery(GET_ACTIVITIES, {
    fetchPolicy: online
      ? 'cache-and-network' // try to keep this query up to date when online
      : 'cache-first',
  });
  const isLoggedIn = !!useSelector(selectToken);
  const activities = get(data, 'my.activities');
  const tokenState = get(data, 'state');
  const staged = useSelector(getStaged);
  const myAnswersSheets = get(data, 'my.answersSheets');
  const byActivityId = useMemo(() => {
    return groupBy(sortBy(myAnswersSheets, 'orderInActivity'), 'activityId');
  }, [
    myAnswersSheets,
  ]);
  const currentActivity = useMemo(() => {
    const withNotCompletedAnswersSheet = find(activities, activity => some(byActivityId[activity.id], isNotCompleted(staged)));
    if (withNotCompletedAnswersSheet) {
      return withNotCompletedAnswersSheet;
    }
    return last(activities);
  }, [
    activities,
    byActivityId,
    staged,
  ]);
  const currentAnswersSheet = useMemo(() => {
    return find(
      currentActivity && byActivityId[currentActivity.id],
      isNotCompleted(staged),
    );
  }, [
    staged,
    byActivityId,
    currentActivity,
  ]);
  const currentAnswersSheetId = currentAnswersSheet && currentAnswersSheet.id;
  const answersSheets = useMemo(() => {
    return map(
      flatten(values(byActivityId)),
      ({
        id,
        state,
        questionnaire,
      }) => ({
        id,
        state,
        questionnaire,
        draftState: staged[id] && staged[id].state,
      }),
    );
  }, [
    staged,
    byActivityId,
  ]);
  const projectLogoUrl = get(currentActivity, 'project.logoUrl');
  const projectLanguagePreference = useReconcile(get(currentActivity, 'project.languages'));
  const patientLanguagePreference = useReconcile(get(data, 'my.languagePreference'));
  const dispatch = useDispatch();
  useEffect(() => {
    forEach(answersSheets, ({
      id,
      state,
    }) => {
      // NOTE: state is remote value take from the server; so if it says "COMPLETED"
      //       it means that the server has confirmed completion
      if (
        state === 'COMPLETED' &&
        staged[id] &&
        staged[id].state === STATE_DRAFT
      ) {
        dispatch(deleteDraft(id));
      }
    });
  }, [
    dispatch,
    staged,
    answersSheets,
  ]);
  useLatestDraftData(currentAnswersSheetId);

  const {
    isFirstInActivity,
    isStarted,
    // isCompleted,
    // mostRecentDraft,
    // localDraftNeedsUpdate,
    loadingQuestionnaire,
    translations,
    // currentQuestionId,
  } = useQuestionnaire(currentAnswersSheetId, null, {
    preferRemoteDraftIfNewer: true,
  });

  const value = useMemo(
    () => ({
      data,
      answersSheetId: currentAnswersSheetId,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      isLoggedIn,
      loading,
      loadingQuestionnaire,
      tokenState,
      shouldContinueActivity:
        isBoolean(isFirstInActivity) &&
        isBoolean(isStarted) &&
        (!isFirstInActivity || isStarted),
    }),
    [
      data,
      currentAnswersSheetId,
      answersSheets,
      translations,
      projectLanguagePreference,
      patientLanguagePreference,
      projectLogoUrl,
      isLoggedIn,
      loading,
      loadingQuestionnaire,
      tokenState,
      isFirstInActivity,
      isStarted,
    ],
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;

export const useDataProvider = () => useContext(DataContext);
