import get from 'lodash/get';
import {
  registerStore,
} from '../common/utilsClient/multiReducer';
import {
  matchToken,
} from '../common/utils/history';
import cleanOnPurge from './clearOnPurge';
import messageBus from '../utils/messageBus';

const initialState = {
  value: null,
};

const store = registerStore({
  path: 'token',
  initialState,
  reducer: cleanOnPurge(initialState),
});

export const selectToken = store.get('value');
export const setToken = store.create.set('value');

export const createMiddleware = () => {
  return ({
    dispatch,
    getState,
  }) => {
    return next => (action) => {
      const previousToken = selectToken(getState());
      const result = next(action);
      const state = getState();
      // eslint-disable-next-line no-underscore-dangle
      if (state._persist && !state._persist.rehydrated) {
        return result;
      }
      const currentToken = selectToken(state);
      if (previousToken && !currentToken) {
        messageBus.emit('loggedOut');
      }
      const location = get(state, 'router.location');
      if (location && location.hash) {
        const {
          hash,
        } = location;
        const hashToken = matchToken(hash);
        if (hashToken && hashToken !== currentToken) {
          dispatch(setToken(hashToken));
        }
      }
      return result;
    };
  };
};

export default store;
