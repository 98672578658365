/* eslint-disable import/no-named-as-default-member */
import cloneDeep from 'lodash/cloneDeep';
import {
  css,
} from 'styled-components';
import theme from '../common/utilsClient/theme';

const newTheme = cloneDeep(theme);

newTheme.typography = {
  caption1: css`
    font-size: ${theme.scale[-2]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.normal};
    letter-spacing: 0.32px;
  `,
  body1: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
  `,
  body2: css`
    font-size: ${theme.scale[-1]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.normal};
    letter-spacing: 0.16px;
  `,
  title1: css`
    font-size: ${theme.scale[2]};
    line-height: ${theme.space[3.5]};
    font-weight: ${theme.font.weight.normal};
  `,
  title2: css`
    font-size: ${theme.scale[1]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
  `,
  title3: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
  `,
  title4: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
  `,
  title5: css`
    font-size: ${theme.scale[0]};
    line-height: ${theme.space[3]};
    font-weight: ${theme.font.weight.normal};
  `,
  title6: css`
    font-size: ${theme.scale[-1]};
    line-height: ${theme.space[2.5]};
    font-weight: ${theme.font.weight.normal};
    letter-spacing: 0.16px;
  `,
};

export default newTheme;
