import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
} from '@heroicons/react/outline';
import React, {
  useEffect,
} from 'react';
import {
  createPortal,
} from 'react-dom';
import PropTypes from 'prop-types';
import createContainer from '../utils/notification/createContainer';

const Notification = ({
  type,
  message,
  duration,
  onClose,
}) => {
  const container = createContainer();

  const icons = {
    success: (
      <CheckCircleIcon className={`w-6 h-6 mr-2 text-${type} flex-shrink-0`} />
    ),
    error: (
      <ExclamationCircleIcon
        className={`w-6 h-6 mr-3 text-${type} flex-shrink-0`}
      />
    ),
    warning: (
      <ExclamationIcon className={`w-6 h-6 mr-3 text-${type} flex-shrink-0`} />
    ),
    info: (
      <InformationCircleIcon
        className={`w-6 h-6 mr-3 text-${type} flex-shrink-0`}
      />
    ),
  };

  useEffect(() => {
    if (duration) {
      const timeoutId = setTimeout(onClose, duration);

      return () => {
        clearTimeout(timeoutId);
      };
    }

    // I don't think I need to return an empty func but eslint says that I do
    return () => {};
  }, [
    duration,
    onClose,
  ]);

  return createPortal(
    <div className="relative py-2 px-4 bg-surface shadow rounded-lg max-w-md w-full flex justify-between items-center">
      <div
        className={`absolute left-0 h-full w-1 rounded-tl-lg rounded-bl-lg bg-${type}`}
      />
      <div className="flex items-center">
        {icons[type]}
        <span className="text-sm">{message}</span>
      </div>
      <button
        type="button"
        onClick={onClose}
      >
        <XIcon className="w-4 h-4" />
        {/* TODO: Translate */}
        <span className="sr-only">Close Notification</span>
      </button>
    </div>,
    container,
  );
};

Notification.propTypes = {
  type: PropTypes.oneOf([
    'info',
    'success',
    'warning',
    'error',
  ]),
  message: PropTypes.string,
  duration: PropTypes.number,
};

Notification.defaultProps = {
  type: 'info',
  message: '...',
  duration: 5000,
};

export default Notification;
